import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const key ='token'
const account_key = 'user_info'

const store =new Vuex.Store({
 state(){
    let token = localStorage.getItem('token')
    let user_info = localStorage.getItem('user_info')
    if (user_info)
        user_info = JSON.parse(user_info)
   return{
     token: token,
     user_info: user_info,
   }
 },
  getters:{
    getToken:function (state) {
      if(!state.token){
        state.token =JSON.parse(localStorage.getItem(key))
      }
      return state.token
    },
    getUserInfo: function(state){
      state.user_info=JSON.parse(localStorage.getItem(account_key))
      return state.user_info
    }
  },
  mutations:{
    setToken(state,value){
      if(value){
        state.token =value;
        localStorage.setItem(key,value)
      }
   },
   setUserInfo(state,account_va){
     if(account_va){
      state.user_info =account_va
      localStorage.setItem("user_info", JSON.stringify(account_va))
     }
   }
  },
})
export default store