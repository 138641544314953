const ko = {
  官網首頁: "RF HOME",
  最新消息: "최근소식",
  綜合: "종합",
  公告: "공지사항",
  活動: "이벤트",
  更新: "업데이트",
  遊戲資訊: "게임정보",
  遊戲地圖: "게임 맵",
  遊戲指南: "게임 가이드",
  社群: "커뮤니티",
  圖片分享區: "사진 공유 영역",
  攻略討論區: "공략게시판",
  玩家討論區: "자유게시판",
  客服中心: "서비스센터",
  "1:1詢問": "1:1문의",
  會員條款: "이용약관",
  下載專區: "다운로드",
  游戲下載: "게임 다운로드",
  商城: "상점",
  儲值點數: "포인트",
  購買元寶: "원보 구매",
  購買記錄: "구매기록",
  儲值記錄: "충전기록",
  會員註冊: "회원가입",
  查詢帳號: "아이디 찾기",
  查詢密碼: "비밀번호 찾기",
  登入: "로그인",
  登出: "로그아웃",
  我的資料: "내 정보",
  密碼更改: "비밀번호 변경",
  "我的點数：": "내 팡캐시 :",
  "購買P-point後": "P-point 구매 후",
  "玩家必須先儲值點數，方可在 購買元寶 頁面購買RF Online的元寶":
    "RF Online 원보를 구매하기 위해서는 먼저 팡캐시를 충전해야 합니다.",
  上一頁: "이전",
  下一頁: "다음",
  例行維護時間: "정기점검 안내",
  每週四: "매주 목요일",
  客服服務時間: "고객센터 운영시간",
  週一至週五: "매주 월~금요일",
  客服信箱: "고객센터 Email",
  視頻: "영상",
  原畫: "일러스트",
  發文: "게시",
  輸入關鍵字: "키워드 입력",
  搜索: "검색",
  全文: "전문",
  主題: "제목",
  會員帳號: "회원 계정",
  "安裝/執行": "설치 / 실행",
  "儲值/購買": "충전 / 구매",
  遊戲問題: "게임 문제",
  消費記錄: "소비기록",
  訂單編號: "주문번호",
  狀態: "상태",
  商品: "상품",
  價格: "가격",
  登錄日: "로그인 날짜",
  實名認證: "본인인증",
  我的禮包: "나의 게임",
  "暱稱：": "닉네임:",
  "帳號：": "계정:",
  帳號: "계정",
  密碼: "비밀번호",
  "信箱：": "메일:",
  信箱: "메일",
  "手機號碼：": "휴대폰 번호:",
  同意接受: "동의합니다. ",
  "《會員服務條款》": "회원 서비스 약관",
  "《個人隱私條款》": "개인 정보 보호 정책",
  "同意接受 Panggame 消息及廣告訊息":
    "Panggame 뉴스 및 광고 메시지 수신에 동의",
  "同意接受在panggame提供的活動抽獎 及遊戲服務同意通知相關sms訊息":
    "Panggame에서 제공하는 이벤트 추첨 및 게임 서비스 수신에 동의하고 관련 SMS 메시지 알림에 동의",
  輸入新密碼: "새 비밀번호 입력",
  請確認密碼: "비밀번호를 확인하세요",
  姓名: "이름",
  請輸入身份證號: "주민등록번호를 입력하세요",
  取消: "취소 ",
  確認: "확인",
  記住登入資訊: "로그인 정보 기억",
  手機找回: "휴대폰으로 찾기",
  信箱找回: "메일 찾기",
  手機: "휴대폰",
  請輸入信箱: "메일을 입력하세요",
  信箱格式有誤: "잘못된 메일 주소입니다.",
  請輸入姓名: "이름을 입력하세요",
  請輸入密碼: "비밀번호를 입력하세요",
  請輸入手機號: "휴대폰 번호를 입력하세요",
  "手機號碼不正確，請重新輸入":
    "휴대전화번호가 올바르지 않습니다. 다시 입력해 주세요.",
  請輸入手機號碼: "휴대폰 번호를 입력하세요",
  "请正确输入您的手机号码，然后重试":
    "휴대폰 번호를 제대로 입력 후 다시 시도해주세요.",
  請輸入文章主題: "게시글 제목을 입력하세요",
  請輸入新的信箱地址: "새 메일주소를 입력하세요",
  請輸入正確帳號: "올바른 계정을 입력하세요",
  請輸入自訂帳號: "닉네임을 입력하세요",
  請輸入角色: "캐릭터명을 입력하세요",
  請輸入帳號: "계정을 입력하세요",
  請輸入郵箱: "이메일을 입력하세요",
  請輸入驗證碼: "인증 코드를 입력하세요",
  "驗證碼為 6 位數字": "인증 코드는 6 자리입니다.",
  獲取驗證碼: "인증 코드 받기",
  驗證碼: "인증 코드",
  修改: "수정",
  信箱註冊: "메일 회원가입",
  手機註冊: "휴대폰 회원가입",
  "歡迎加入panggame!": "panggame에 가입하신 것을 환영합니다!",
  "爲使用遊戲，請按照以下的格式進行註冊流程。":
    "게임을 이용하기 위해서는 회원가입 절차를 따라 가입을 진행해주시기 바랍니다.",
  "完成註冊，即刻獲得上市祝福禮包！":
    "회원가입을 완료하고 즉시 오픈 축하 패키지를 받으십시오!",
  購買: "구입",
  "點數是什麼？": "팡캐시는 무엇입니까?",
  "RF Online 點數購買": "RF 캐쉬구매 / 선물",
  "儲值點數時，請先確認金額後再購買":
    "RF 캐쉬 구매 시 구매할 서버와 금액을 확인 후 구매해 주시기 바랍니다.",
  購買成功: "구매성공",
  點数不足: "팡캐시 부족",
  "消耗點數：": "소모캐시 :",
  "當前點數：": "팡캐시 잔액:",
  "購買價格：": "구매가격:",
  "道具資訊：": "아이템 정보 :",
  "持有點數：": "보유 캐시 :",
  "扣除後點數：": "캐시 잔액:",
  您正在購買元寶: "원보 구매 중입니다.",
  取消購買: "구매취소",
  確認購買: "구매 확인",
  "儲值點數：": "팡캐시 충전금액:",
  "儲值後點數：": "충전 후 캐시 :",
  暱稱僅有一次修改機會: "닉네임은 한 번만 변경할 수 있습니다.",
  輸入新暱稱: "새 닉네임 입력",
  "舊信箱地址：": "이전 이메일 주소 :",
  "新信箱地址：": "새 이메일주소 :",
  "舊手機號碼：": "이전 휴대전화번호 :",
  "新手機號碼：": "새 휴대전화번호 :",
  "手機：": "휴대전화번호 :",
  發送成功: "발송성공",
  上載失敗: "업로드 실패",
  "兩次輸入的密碼並不相符，請重新輸入": "비밀번호가 일치하지 않습니다.",
  請勾選同意接受條款: "약관 동의를 확인해 주세요. ",
  註冊成功: "가입완료",
  分類: "분류",
  問題: "문의",
  聊天: "잡담",
  建議: "건의",
  留言: "댓글작성",
  上留言: "댓글작성",
  回復: "답변",
  请输入关键字: "관련키워드 입력",
  "發佈辱罵,相同文章重覆發文,誹謗,謠言等違反營運政策的貼文時，可能會受到限制發文，若有違法情事發生，自行負擔法律相關責任. 最長100個字元":
    "도배, 욕설 및 지나친 모욕, 음란물 및 허위 사실을 유포하는 등 운영 정책에 위배되는 게시물은 제한 될 수 있으며, 위반시 법적 책임은 본인에게 있습니다. 최대 100자",
  使用點數: "사용캐시",
  當前點數: "팡캐시 잔액",
  商品名: "아이템명",
  確認密碼: "비밀번호 확인",
  您的信箱地址: "이메일주소를 입력해 주세요.",
  請輸入賬號: "계정을 입력하세요",
  "密碼長度最少為 8 字元": "비밀번호는 8 자 이상이어야 합니다.",
  密碼不能有特殊字元: "비밀번호는 특수문자를 포함할 수 없습니다.",
  "自訂賬號需為英文字母和數字的組合，且第一個字元必須為英文，英文及數字必須為半形":
    "아이디는 영문 또는 영문+숫자 조합이며, 영문으로 시작해야 합니다.",
  "自訂賬號長度最多為 32 字元": "아이디는 최대 32 자입니다.",
  修改成功: "변경완료",
  未查找到該賬號: "존재하지 않는 계정입니다.",
  暫無數據: "데이터 없음",
  "賬號第一位必須為英文字母，最多 32 個字元":
    "아이디는 영문으로 시작되어야 합니다. (최대 32 자)",
  請輸入自訂賬號: "닉네임을 입력하세요",
  立即註冊: "회원가입",
  極速下載: "다운로드",
  新手指南: "게임가이드",
  裝備技能: "장비기능",
  進階攻略: "진급공략",
  其他: "기타",
  角色介绍: "캐릭터 소개",
  刪除: "삭제",
  刪除文章: "게시글삭제",
  刪除留言: "댓글삭제",
  "確定要刪除嗎？": "삭제하시겠습니까?",
  復制成功: "복사성공",
  "同時使用 手機號碼 作為登入帳號，取消勾選可自訂登入帳號":
    "휴대전화번호 정보를 로그인 아이디로 사용합니다. 체크 해제 시 하단에 아이디를 입력해 주세요.",
  联系我们: "제휴문의",
  政策中心: "정책센터",
  我的主页: "마이페이지",
  "您可以编辑您的个人信息并管理服务应用程序。我们将尽力保护您的个人信息。":
    "본인의 개인정보 수정 및 서비스 신청 관리를 진행하실 수 있습니다. 고객님의 개인정보 보호를 위해 최선을 다하겠습니다.",
  个人信息管理: "개인정보 관리",
  "我们仅存储最少的个人信息。": "최소한의 개인정보만을 저장합니다.",
  "成员通过其账号ID和电子邮件地址确定他们自己。":
    "회원은 아이디와 메일주소로 본인여부를 판단합니다.",
  "您可以检查优惠券详细信息。": "회원님의 쿠폰내역을 확인하실 수 있습니다.",
  "检查详细信息后，如果有任何问题，请联系客服中心。":
    "내역을 확인하신 후 이상이 있을 경우 고객센터로 문의해 주시기 바랍니다.",
  玩家昵称: "닉네임",
  现金: "캐시",
  管理个人信息: "개인정보 관리",
  修改个人信息: "개인정보 수정",
  修改密码: "비밀번호 변경",
  积分商城: "포인트샵",
  "欢迎加入panggame!": "팡게임에 오신 것을 환영합니다!",
  "为使用游戏，请按照以下的格式进行注册流程":
    "게임 이용을 위해서는 아래 양식에 따라 회원가입 절차를 진행해 주세요.",
  商品交换: "상품교환",
  查看内容: "내역조회",
  查看积分记录: "포인트 획득내역",
  查看兑换记录: "포인트 사용내역",
  积分使用指南: "포인트 이용안내",
  "panggame通过各种活动来支付PANG积分，将手机的积分与新介绍的丰富多样的优惠。":
    "묵혼 원보 구매액의 1%를 PANG 포인트로 적립해드립니다.적립된 포인트는 푸짐하고 다양한 혜택으로 교환하실 수 있습니다.",
  每周更新一次:
    "교환 신청한 묵혼원보는 신청 이후에 진행되는 정기점검일에 지급됩니다.",
  剩余数量: "남은 수량",
  兑换: "교환하기",
  我的优惠券: "나의 쿠폰",
  礼包码: "PIN코드",
  礼品券: "상품권",
  注销账号: "회원탈퇴",
  "实名验证是一种注册过程，用于在您首次注册时验证您的身份。":
    "본 실명확인은 최초 가입 시 본인 확인을 위한 가입 절차 입니다.",
  "输入的信息将提供给NICE信用评级信息有限公司，以确认其真实姓名。":
    "입력하신 정보는 실명확인을 목적으로 NICE신용평가정보(주)에 제공되며,",
  "PangSky Co.，Ltd.不收集居民登记号。":
    "(주)팡스카이에서는 주민등록번호를 수집하지 않습니다.",
  "注意：": "주의 : ",
  "如果您窃取他人的居民登记号码和信息，根据居民登记号码，您可能会受到最高3年的监禁或最高1000万韩元的罚款，并且会员登记可能被取消，恕不另行通知。":
    "타인의 주민등록번호 및 정보를 도용할 경우 주민등록번에 따라 3년이하의 징역 또는 1천만원 이하의 벌금에 처해지며 사고 예고 없이 회원가입이",
  "我同意接受 服务条款及条件": "이용약관 동의하기",
  我同意收集和使用个人信息: "개인정보처리방침 동의하기",
  "1.注销后，您将无法使用属于Panggame的所有游戏，会员服务以及现金使用等服务。":
    "1.탈퇴 후에는 팡게임에 소속된 모든 게임과 회원 서비스, 캐시이용 등의 서비스를 이용하실 수 없습니다.",
  "2.注销后使用的帐户和昵称不能重复使用。":
    "2.탈퇴 후 사용하셨던 계정 및 닉네임은 다시 사용할 수 없습니다.",
  "3.注销过程结束后，所有信息将被删除。":
    "3.탈퇴 진행 후에는 회원님의 모든 정보가 삭제됩니다.",
  "*请选择手机或Email接收验证码确认后退出会员。":
    "*회원탈퇴 방식을 선택해 주세요.",
  "若手机号或Email都不再使用，请通过1:1询问人工处理。":
    "회원가입 시 사용하신 휴대폰 번호와 메일 확인이 어려우시면 1:1 상담을 부탁드립니다.",
  服务条款: "이용약관",
  个人信息收集协议: "개인정보처리방침",
  手机服务条款: "모바일 이용약관",
  手机个人信息收集协议: "모바일 개인정보처리방침",
  青少年保护政策: "청소년 보호 정책",
  使用条款: "이용약관",
  "隐私条款-收费服务条款": "결제이용약관",
  "版权所有©PangSky公司 保留所有权利。":
    "Copyright © PangSky Corp. All rights reserved.",
  "公司名称：FangSky Co.，Ltd.": "상호: (주)팡스카이",
  "商业登记号：119-86-62250": "사업자등록번호 : 119-86-62250",
  "邮购商业登记号：2014-Seoul Gangnam-01064":
    "통신판매업신고번호：2014-Seoul Gangnam-01064",
  "代表董事：Ji Heon-min": "대표이사 : 지헌민",
  "地址：25 Ganghyeon-ro 80-gil，Nannam-首尔区（Jeongksam洞，Jeongwoo大楼）":
    "주소 : 서울시 강남구 논현로80길 25(역삼동,정우빌딩)",
  "传真：070-8233-9383，": "팩스 : 070-8233-9383, ",
  "客户中心：070-4658-9412，panggamehelp@panggame.com":
    "고객센터 : 070-4658-9412, panggamehelp@panggame.com",
  隐私政策: "개인정보처리방침",
  旧隐私政策: "오래된 개인정보처리방침",
  付款条款和条件: "결제이용약관",
  公司介绍: "회사소개",
  復制券碼: "PIN코드 복사",
  "您是否确认注销账号？": "계속하여 회원탈퇴를 진행하시겠습니까? ",
  暂无任何礼券: "현재 준비된 상품권이 없습니다.",
  企业合作以及服务提供商: "퍼블리싱 및 기술 서비스 제공 기업",
  优惠券管理: "쿠폰관리",
  累计明细: "적립내역",
  日期: "일자",
  积分累积记录: "적립내용",
  获得积分: "적립포인트",
  我的积分: "보유 포인트",
  交换商品: "상품 교환",
  交换个数: "교환 수량",
  所需积分: "필요 포인트",
  个: "개",
  "*密码至少要包含8位英文和数字":
    "*비밀번호는 최소 8자리를 영문과 숫자가 포함되어야 합니다",
  密码至少要包含8位英文和数字:
    "비밀번호는 최소 8자리를 영문과 숫자가 포함되어야 합니다",
  绑定成功: "연동성공",
  查询账号密码: "아이디 및 비밀번호 찾기",
  "還沒有收到驗證信件？": "인증 이메일을 받지 못하셨습니까?",
  "網路會有一定延遲，請等幾分鐘後再去看看。":
    "네트워크 오류가 발생했을 수도 있습니다. 잠시 후 다시 시도해 주시기 바랍니다.",
  "可能被信箱過濾，請到廣告信件、垃圾信件或者相關信件歸檔中查看。":
    "이메일에서 필터링이 될 수 있으니 스팸편지함을 확인하시기 바랍니다.",
  "請確認一下Email地址是否拼寫錯誤，您可以用正確的信箱地址重新註冊。":
    "이메일 주소를 정확히 입력하셨나요? 정확한 이메일 주소로 다시 가입 하실수 있습니다.",
  解绑: "해지",
  需要绑定邮箱才能解绑手机号:
    "메일을 연동해야 휴대폰번호를 해제할 수 있습니다.",
  添加: "추가",
  "您正在解绑手机，需要验证旧手机，若旧手机无法接收验证码，请使用邮箱进行验证。":
    "연동해제 시 이전 휴대폰번호 필요, 만약 이전 휴대폰번호 인증 불가 시 이메일로 인증가능",
  解绑成功: "해제성공",
  昵称重复: "닉네임중복",
  请输入昵称: "닉네임을 입력하세요",
  "商品券码会发送到您的手机或邮箱。":
    "상품권PIN코드는 휴대폰번호로 발송됩니다.",
  商品券码: "상품권PIN",
  商品券码已发送: "상품권 PIN 번호 발송이 완료되었습니다.",
  "成功退出会员。": "회원 탈퇴가 완료 되었습니다",
  兌換成功: "교환성공",
  更换代表后新落款:
    "(주)팡스카이 대표이사 이병진, 대표이사 조호현 사업자 등록번호: 119-86-62250 통신판매업 신고번호: 제 2022-서울구로-2036",
  更换代表后新地址和联系方式:
    "서울시 구로구 디지털로31길 20, 1201호(구로동, 에이스테크노타워 5차) 팩스: 070-8233-9383",
};

export default ko;
