import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/css/reset.min.css'
import 'lib-flexible'
import axios from 'axios';
import VueI18n from 'vue-i18n'
import ko from './lang/ko'
Vue.config.productionTip = false;
Vue.prototype.$axios = axios
Vue.use(Antd);
Vue.use(VueI18n)
const messages = {
  ko: ko,
}
const i18n = new VueI18n({
  locale:  'ko',            //从sessionStorage中拿到用户的语言选择，如果没有，那默认中文。
  messages,
  silentTranslationWarn: true,
})

router.beforeEach((to,from,next)=>{
  if(to.matched.some(res=>res.meta.requireAuth)){//判断是否需要登录
    let t = to.query.token
    // console.log('store:'+store.state.token)
    // console.log('t:'+to.query.token)
      if (store.state.token || t) {
          next();
      }else{
          next({
              path:"/login",
              query:{
                  redirect:to.fullPath
              }
          });
      }

  }else{
      next()
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
