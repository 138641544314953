<template>
  <div id="header">
    <div class="pc" v-if="fullWidth > 900">
      <!-- <a-row>
                <a-col :span="18"> -->
      <div class="header-logo">
        <a href="http://panggame.com/" target="_blank">
          <img src="../assets/img/hlogo01.png" alt="" />
        </a>
      </div>
      <!-- </a-col>
                <a-col :span="6"> -->
      <ul class="header-list">
        <li>
          <router-link to="/contact">{{ this.$t("联系我们") }}</router-link>
        </li>
        <li>
          <router-link to="/integralshop">{{
            this.$t("积分商城")
          }}</router-link>
        </li>
        <li><router-link to="/mypage/center">My Page</router-link></li>
        <li>
          <router-link to="/register">{{ this.$t("會員註冊") }}</router-link>
        </li>
        <li>
          <router-link v-if="!this.$store.state.user_info" to="/login">{{
            this.$t("登入")
          }}</router-link>
          <span v-if="this.$store.state.user_info">{{
            $store.state.user_info.nickname
          }}</span>
        </li>
      </ul>
      <!-- </a-col>
            </a-row> -->
    </div>
    <div v-else class="mb-header">
      <div class="h5-item" :style="sliderStyle">
        <div @click="makeMenu" style="width: 32%" class="h5-menu">
          <img src="../assets/img/ico_mobile_nav_head.png" alt="" />
        </div>
        <div class="logo" style="width: 32%">
          <router-link :to="{ path: 'home' }"> </router-link>
        </div>
        <div style="width: 32%" class="user">
          <span v-if="username == null"
            ><router-link style="color: #fff" :to="{ path: '/login' }"
              >登录</router-link
            ></span
          >
          <span v-if="username != null" @click="showDrawer"
            ><img src="../assets/img/ico_register_head.png" alt=""
          /></span>
        </div>
      </div>
      <transition name="toggle-cart">
        <ul :style="sliderStyle" class="h5-menu-item" v-show="!dismenu">
          <li @click="makeMenu">
            <router-link to="/contact">{{ this.$t("联系我们") }}</router-link>
          </li>
          <li @click="makeMenu">
            <router-link to="/integralshop">{{
              this.$t("积分商城")
            }}</router-link>
          </li>
          <li @click="makeMenu">
            <router-link to="/mypage/center">My Page</router-link>
          </li>
          <li @click="makeMenu">
            <router-link to="/register">{{ this.$t("會員註冊") }}</router-link>
          </li>
          <li @click="makeMenu">
            <router-link to="/login">{{ this.$t("登入") }}</router-link>
          </li>
        </ul>
      </transition>
      <div class="mb-menu"></div>
      <a-drawer
        title="My Page"
        placement="left"
        :closable="true"
        :visible="visible"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
      >
        <User></User>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import User from "./User";
// import Bus from '../utils/bus';
export default {
  name: "Header",
  components: {
    User,
  },
  data() {
    return {
      visible: false,
      fullWidth: 0,
      fullShow: true,
      dismenu: true,
      sliderStyle: {
        width: "",
      },
      username: "",
    };
  },
  methods: {
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
      this.sliderStyle.width = this.fullWidth + "px";
    },
    makeMenu() {
      //点击导航图标
      this.dismenu = !this.dismenu;
    },
    showToggle: function (item) {
      item.isSubshow = !item.isSubshow;
    },
    closemenu() {
      this.dismenu = !this.dismenu;
    },
    showUserCenter() {
      this.usercenter = !this.usercenter;
    },
    unshowUserCenter() {
      this.usercenter = !this.usercenter;
    },
    onClick({ key }) {
      console.log(`Click on item ${key}`);
    },
    goLogin() {
      this.$router.push("/login");
    },
    goOut() {
      let _this = this;
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user_info");
      setTimeout(function () {
        _this.$router.go(0);
      }, 1000);
    },
    getInfo() {
      if (this.$store.state.user_info) {
        let userinfo = this.$store.state.user_info;
        this.username = userinfo;
      }
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    afterVisibleChange(val) {
      console.log("visible", val);
    },
  },
  mounted() {
    this.handleResize();
    // this.getInfo();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    // Bus.$on("getusername",()=>{
    //     this.getInfo();
    // })
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss">
#header {
  height: 40px;
  line-height: 40px;
  border-bottom: 2px solid #ccc;
  background-color: #fafafa;
}
@media only screen and (max-width: 900px) {
  #header {
    background-color: #000;
    height: 80px;
    line-height: 80px;
    position: fixed;
    top: 0;
    z-index: 999;
  }
}
#header .pc {
  display: flex;
}
#header .pc div {
  flex-grow: 1;
}
#header .header-logo {
  text-align: left;
  padding-left: 50px;
  cursor: pointer;
}
#header .header-logo img {
  width: 150px;
}
#header .header-list {
  display: flex;
  text-align: right;
  padding-right: 50px;
}
#header .header-list li {
  flex-grow: 1;
  margin-right: 20px;
}
#header .header-list li a {
  color: #808080;
  font-size: 20px;
}
.mb-header {
  .h5-item {
    display: flex;
    width: 100%;
    padding: 0 10px;
    .logo {
      img {
        width: 100%;
      }
    }
    .user {
      text-align: right;
    }
    .h5-menu {
      text-align: left;
      img {
        width: 50px;
      }
    }
  }
  .h5-menu-item {
    position: fixed;
    z-index: 998;
    top: 60px;
    background-color: #000;
    opacity: 0.9;
    li {
      margin-top: 10px;
      line-height: 80px;
      text-align: left;
      border-bottom: 1px solid #969696;
      a {
        color: #fff;
        text-decoration: none;
        margin-left: 10px;
        display: inline-block;
        height: 100%;
        width: 100%;
      }
      .mbtitle {
        line-height: 60px;
        text-indent: 10px;
        margin: 0;
        color: #000;
        font-size: 16px;
        position: relative;
        span {
          position: absolute;
          right: 10px;
          img {
            width: 40px;
          }
        }
        ul {
          li {
            h3 {
              a {
                font-size: 14px;
                margin-left: 20px !important;
              }
            }
          }
        }
      }
    }
  }
  .mb-menu {
    height: 50px;
  }
}
</style>
