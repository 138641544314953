<template>
  <div id="footer">
    <div class="footer-item">
      <ul>
        <li>
          <router-link to="/contact">{{ $t("公司介绍") }}</router-link>
        </li>
        <a-divider type="vertical" />
        <li>
          <router-link to="/policy?tab=0">{{ $t("使用条款") }}</router-link>
        </li>
        <a-divider type="vertical" />
        <li>
          <router-link to="/policy?tab=1"
            ><span style="color: #1890ff; text-decoration: underline">{{
              $t("隐私政策")
            }}</span></router-link
          >
        </li>
        <a-divider type="vertical" />
        <li>
          <router-link to="/policy?tab=2">{{
            $t("付款条款和条件")
          }}</router-link>
        </li>
        <a-divider type="vertical" />
        <li>
          <router-link to="/contact">{{ $t("客服中心") }}</router-link>
        </li>
      </ul>
    </div>
    <div class="footer-cont">
      <!-- <p>
        {{ $t("公司名称：PangSky Co.，Ltd.") }}
        {{ $t("商业登记号：119-86-62250") }}
        {{ $t("邮购商业登记号：2014-Seoul Gangnam-01064") }}
      </p> -->
      <p>{{ $t("更换代表后新落款") }}</p>
      <!-- <p>
        {{ $t("代表董事：Ji Heon-min") }}
        {{
          $t(
            "地址：25 Ganghyeon-ro 80-gil，Nannam-首尔区（Jeongksam洞，Jeongwoo大楼）"
          )
        }}
        {{ $t("传真：070-8233-9383，") }}
        {{ $t("客户中心：070-4658-9412，panggamehelp@panggame.com") }}
      </p> -->
      <p>{{ $t("更换代表后新地址和联系方式") }}</p>
      <p>{{ $t("版权所有©PangSky公司 保留所有权利。") }}</p>
      <p><img src="../assets/img/logo01.png" alt="" /></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fullWidth: 0,
    };
  },
  methods: {
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
    },
  },
  mounted() {
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
#footer {
  background-color: #222426;
  color: #fff;
  padding: 100px 0;
  width: 100%;
  text-align: center;
  // position: fixed;
  // bottom: 0;
  @media only screen and (max-width: 900px) {
    padding: 100px 30px;
  }
  .footer-item {
    max-width: 1350px;
    margin: 0 auto;
    ul {
      display: flex;

      padding-bottom: 20px;
      li {
        flex-grow: 1;
        text-align: center;
        a {
          color: #fff;
          &:hover {
            color: #1384ff;
          }
        }
      }
      .ant-divider,
      .ant-divider-vertical {
        height: 25px;
        background: #3b3b3a;
      }
    }
  }
  .footer-cont {
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    color: #626262;
    border-top: 1px solid #3b3b3a;
    padding-top: 30px;
    img {
      display: inline-block;
      margin-top: 20px;
    }
  }
}
</style>
