import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/mypage/center",
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/mypage",
    name: "mypage",
    component: () => import("../views/MyPage/MyPage.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "/mypage/center",
        name: "center",
        component: () => import("../views/MyPage/UserCenter.vue"),
      },
      {
        path: "/mypage/coupon",
        name: "coupon",
        component: () => import("../views/MyPage/Coupon.vue"),
      },
      {
        path: "/mypage/userdata",
        name: "userdata",
        component: () => import("../views/MyPage/UserData/UserData.vue"),
      },
    ],
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact/Contact.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/policy",
    name: "policy",
    component: () => import("../views/Policy/Policy.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/Register/Register.vue"),
  },
  {
    path: "/integralshop",
    name: "integralshop",
    component: () => import("../views/IntegralShop/IntegralShop.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/find",
    name: "find",
    component: () => import("../views/FindAccountPwd/Find.vue"),
  },
];

const router = new VueRouter({
  routes,
});

function parseSearchParams(search) {
  let searchParamsString = search.slice(1);
  return searchParamsString.split("&").reduce((searchParams, curKV) => {
    const [k, v] = curKV.split("=").map(decodeURIComponent);
    searchParams[k] = v;
    return searchParams;
  }, {});
}

router.beforeEach((to, from, next) => {
  let search = window.location.search;
  if (window.location.pathname != "/") {
    window.history.replaceState({}, "panggame", window.location.origin);
    next();
  } else if (search) {
    window.history.replaceState({}, "panggame", window.location.origin);
    let query = parseSearchParams(search);
    next({
      path: to.path,
      query: {
        ...to.query,
        ...query,
      },
    });
  } else if (from.query.utm_source && !to.query.utm_source) {
    let query = {};
    if (from.query.utm_source) query.utm_source = from.query.utm_source;
    if (from.query.utm_campaign) query.utm_campaign = from.query.utm_campaign;
    if (from.query.utm_medium) query.utm_medium = from.query.utm_medium;
    next({
      path: to.path,
      query: {
        ...to.query,
        ...query,
      },
    });
  } else {
    next();
  }
});

export default router;
