<template>
    <div class="user" v-if="this.$store.state.token != 'null' && this.$store.state.token != null">
        <div class="user-name">
            <div class="name"><p>PANG MEMBERS</p></div>
            <div class="nickname">
                <p>{{this.$t('玩家昵称')}}: <span v-if="this.$store.state.user_info">{{$store.state.user_info.nickname}}</span></p>
                <p>pang{{this.$t('现金')}}：
                    <span v-if="this.$store.state.user_info">{{originPriceFormat($store.state.user_info.game_cash)}}</span>
                    <span>
                        <a-tooltip placement="topLeft">
                            <template slot="title">
                                <p>정상적으로 결제하여 충전된 현금 캐쉬.</p>
                                <p>- 환불 가능</p>
                            </template>
                            <img class="dou" src="../assets/img/icon_doubt.png" alt="">
                        </a-tooltip>
                    </span>
                </p>
            </div>
            <div class="page">
                <p><router-link to="/mypage/center">My Page</router-link></p>
                <p>
                    <span v-if="!this.$store.state.user_info"><router-link to="/login">{{this.$t('登入')}}</router-link></span>
                    <span v-if="this.$store.state.user_info" @click="logOut">{{this.$t('登出')}}</span>
                </p>
            </div>
        </div>
        <!-- <div class="user-new">
            <div class="newT">
                <p>新消息</p>
            </div>
            <div class="newC">
                <ul>
                    <li>新闻消息内容内容内容内容...</li>
                    <li>新闻消息内容内容内容内容...</li>
                    <li>新闻消息内容内容内容内容...</li>
                    <li>新闻消息内容内容内容内容...</li>
                </ul>
            </div>
        </div> -->
        <div class="user-page">
            <div class="pageT">
                <p>{{this.$t('我的主页')}}</p>
            </div>
            <div class="page-item">
                <p><router-link to="/mypage/userdata?tab=0">{{this.$t('管理个人信息')}}</router-link></p>
                <p><router-link to="/mypage/userdata?tab=1">{{this.$t('修改个人信息')}}</router-link></p>
                <p><router-link to="/mypage/userdata?tab=1">{{this.$t('修改密码')}}</router-link></p>
            </div>
        </div>
    </div>
</template>

<script>
// import Bus from '../utils/bus';
import request from "../api/request"
import filters from "../utils/filters"
export default {
    data(){
        return{
            userdata:''
        }
    },
    mounted(){
        // this.getInfo()
        if(this.$store.state.token != 'null' && this.$store.state.token != null){
            this.getMe()
        }
    },
    methods:{
        originPriceFormat: filters.priceFormat,
        getMe(){
            let _this = this
            request.get('/users/me', {}, true).then(function (res) {
                if (res.status == 200) {
                    if(res.data){
                        _this.$store.commit("setUserInfo", res.data);
                    }
                }
            })
        },
        logOut(){
            let _this = this
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('user_info')
            setTimeout(function (){
                _this.$router.go(0)
            }, 2000)
        },
        // getInfo(){
        //     if(this.$store.state.user_info){
        //         let userinfo = this.$store.state.user_info
        //         this.userdata = userinfo;
        //     }
        // },
    },
    created(){
        // Bus.$on("getusername",()=>{
        //     this.getInfo()
        // })
    },
}
</script>

<style lang="scss">
.user{
    div{
        background-color: #fff;
        p{
            text-align: left;
            margin: 10px 30px;
            padding: 5px 0;
            
        }
    }
    a{
        color: #886C6C;
        &:hover{
            color: #886C6C;
        }
    }
    .user-name{
        margin:20px;
        width: 300px;
        @media only screen and (max-width: 900px){
            width: 400px;
        }
        .name{
            font-size: 22px;
            color: #333333;
        }
        .nickname{
            border-top: 1px solid #E1E1E1;
            border-bottom: 1px solid #E1E1E1;
            font-size: 20px;
            span{
                vertical-align: middle;
            }
            p{
                &:nth-child(1){
                    color: #534141;
                }
                &:nth-child(2){
                    color: #999999;
                    span{
                        color: #D48D05;
                    }
                }
            }
            .dou{
                margin-left: 20px;
                display: inline-block;
                cursor: pointer;
                width: 20px;
                height: 21px;
            }
        }
        .page{
            color: #886C6C;
            font-size: 20px;
            display: flex;
            p{
                flex-grow: 1;
                text-align: center;
                cursor: pointer;
                &:nth-child(1){
                    border-right: 1px solid #E1E1E1;
                }
            }
        }
    }
    .user-new{
        margin: 20px;
        width: 300px;
        .newT{
            font-size: 18px;
            color: #333333;
            border-bottom: 1px solid #E1E1E1;
        }
        .newC{
            color: #886C6C;
            font-size: 20px;
            padding: 5px 0 5px 0;
            ul{
                li{
                    padding: 0 10px;
                    &::before{
                        content: "";
                        width: 2px;
                        height: 3px;
                        display: inline-block;
                        border-radius: 50%;
                        background: #886C6C;
                        vertical-align: middle;
                        margin-right: 10px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .user-page{
        margin: 20px;
        .pageT{
            font-size: 22px;
            color: #333333;
            border-bottom: 1px solid #E1E1E1;
        }
        .page-item{
            p{
                color: #886C6C;
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
}
</style>