import axios from "axios";
import { message } from "ant-design-vue";
import router from "../router";

axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

// 根据 VUE_APP_BASE_URL 生成 baseUrl
let baseUrl = process.env.VUE_APP_BASE_URL
  ? process.env.VUE_APP_BASE_URL
  : "http://localhost:8021/";
let staticUrl =
  process.env.VUE_APP_BASE_URL.slice(0, 4) != "http"
    ? ""
    : "https://test-accountkr.panggame.com/";
if (!baseUrl.match(/^https?/i)) {
  baseUrl = `${window.location.protocol}//${window.location.host}${process.env.VUE_APP_BASE_URL}`;
}

// 如果 Docker 中设置了 CRAWLAB_API_ADDRESS 这个环境变量，则会将 baseUrl 覆盖
const CRAWLAB_API_ADDRESS = "###CRAWLAB_API_ADDRESS###";
if (!CRAWLAB_API_ADDRESS.match("CRAWLAB_API_ADDRESS")) {
  baseUrl = CRAWLAB_API_ADDRESS;
}

const request = (method, path, params, data, _this, hideError, others = {}) => {
  const url = baseUrl + path;
  const headers = {
    Authorization: "Bearer " + window.localStorage.getItem("token"),
  };
  return axios({
    method,
    url,
    params,
    data,
    headers,
    ...others,
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    })
    .catch((e) => {
      let response = e.response;
      if (!response) {
        return e;
      }
      if (response.status === 401 && !hideError) {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("user_info");
        router.replace("/login");
      }
      if (response.status === 400 && !hideError) {
        message.error(response.data.detail);
      }
      return response;
    });
};

const get = (path, params, hideError) => {
  return request("GET", path, params, null, hideError);
};

const post = (path, data, hideError) => {
  return request("POST", path, {}, data, null, hideError);
};

const put = (path, data, hideError) => {
  return request("PATCH", path, {}, data, null, hideError);
};

const del = (path, hideError) => {
  return request("PATCH", path, {}, { status: 0 }, null, hideError);
};

export default {
  baseUrl,
  staticUrl,
  request,
  get,
  post,
  put,
  delete: del,
};
